<template>
  <div class="market">
    <div class="tab" :class="navBarFixed ? 'navBarFixed' : ''">
      <img @click="goback" src="../../../../static/common/logoBlack.png" alt="" />
    </div>
    <div class="banner"></div>
    <div class="cont">
      <div class="who-app">
        <img src="../../../../public/activity/market/whoapp.png" alt=""/>
      </div>
      <div class="cont1"></div>
      <div class="cont2"></div>
      <div class="cont3"></div>
      <div class="cont4"></div>
      <div class="left1"></div>
      <div class="left2"></div>
      <div class="right1"></div>
      <div class="foot-con">
        <div class="trace"></div>
        <div class="line99"></div>
        <div class="qrcode-c">
          <div class="left"></div>
          <div class="right"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "market",
  data() {
    return {};
  },
  created() {
    this.$emit("controlShow", true);
    if (document.body.clientWidth < 500) {
      this.$router.push({
        name: "market_wap",
      });
    }
  },
  methods: {
    goback() {
      window.location.href = window.location.origin;
    },
    getActivity() {
      window.open("https://www.rlcvipltd.net/uc/signUp/real");
    },
    getLive800() {
      // window.open(
      //   "https://chat32.live800.com/live800/chatClient/chatbox.jsp?companyID=215184&configID=35519&jid=5377035377"
      // );
      window.open(this.$parent.live800UrlUc)  
    },
    getDownloadApp() {
      window.open("https://oss.0790jiaxiao.com/HS/software/hs_9999.apk");
    },
  },
};
</script>

<style lang="less">
.tab {
  width: 100%;
  height: 60px;
  background-color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  padding-left: 10px;
}
.navBarFixed {
  position: fixed;
  top: 0;
  z-index: 999;
}
.market {
  width: 100%;
  // height: 5000px;
  .banner {
    max-width: 1920px;
    height: 600px;
    background: url("/activity/market/bg.jpg") no-repeat center;
    background-size: cover;
  }
  .cont {
    width: 1920px;
    height: 3820px;
    background: url("/activity/market/con-bg.png") no-repeat center;
    background-size: cover;
    .who-app {
      width: 100%;
      height:188px;
      line-height: 188px;
      padding-left: 309px;
      padding-top: 65px;
    }
    .cont1 {
      width: 1300px;
      height: 640px;
      margin: 0 auto;
      background: url("/activity/market/con1.png") no-repeat center;
      background-size: cover;
      margin-bottom: 85px;
      
    }
    .cont2 {
      width: 1300px;
      height: 640px;
      margin: 0 auto;
      background: url("/activity/market/con2.png") no-repeat center;
      background-size: cover;
      margin-bottom: 85px;
    }
    .cont3 {
      width: 1300px;
      height: 640px;
      margin: 0 auto;
      background: url("/activity/market/con3.png") no-repeat center;
      background-size: cover;
      margin-bottom: 85px;
    }
    .cont4 {
      width: 1300px;
      height: 640px;
      margin: 0 auto;
      background: url("/activity/market/con4.png") no-repeat center;
      background-size: cover;
      margin-bottom: 85px;
    }
    .foot-con {
      width: 100%;
      position: relative;
      top: -2151px;
      .trace {
        width: 764px; 
        height: 60px;
        margin: 0 auto;
        background: url("/activity/market/tit.png") no-repeat center;
        background-size: 100%;
      }
      .line99 {
        width: 100%;
        height: 99px;
      }
      .qrcode-c {
        width: 1373px;
        height: 270px;
        margin: 0 auto;
        .left {
          width: 743px;
          height: 238px;
          background: url("/activity/market/tit2.png") no-repeat center;
          background-size: 83%;
          float: left;
        }
        .right {
          width: 627px;
          height: 238px;
          float: left;
          background: url("/activity/market/ios.png") no-repeat center;
          background-size: 83%;
        }
      }
    }
    .left1 {
      width: 90px;
      height: 737px;
      background: url("/activity/market/l.png") no-repeat center;
      background-size: 100%;
      position: relative;
      top: -2577px;
      left: 220px;
    }
    .left2 {
      width: 90px;
      height: 737px;
      background: url("/activity/market/l.png") no-repeat center;
      background-size: 100%;
      position: relative;
      top: -1872px;
      left: 220px;
    }
    .right1 {
      width: 98px;
      height: 740px;
      background: url("/activity/market/r.png") no-repeat center;
      background-size: 100%;
      position: relative;
      top: -3348px;
      left: 1613px;
    }
  }
}
::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  background-color: none;
}

::-webkit-scrollbar-thumb {
  background-color: none;
}

::-webkit-scrollbar-thumb:hover {
  background-color: none;
}

::-webkit-scrollbar-thumb:active {
  background-color: none;
}
</style>